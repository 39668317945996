import React from 'react';
import { Button } from '@mui/material'; // or '@mui/material' depending on your setup
import { useNavigate } from 'react-router-dom';

export function LinkButton({ link, text, color, variant, boxShadow }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <Button variant={variant} color={color} onClick={handleClick} boxShadow={boxShadow}>
      {text}
    </Button>
  );
}

LinkButton.defaultProps = {
    color: 'primary',
    variant: 'contained',
    boxShadow: 'none'
    };
    