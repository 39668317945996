import React from 'react';
import { Container, Box, Typography, Card, CardContent } from '@mui/material';

export function VideoSectionComingSoon() {
  return (
    <Container sx={{ py: 8 }}>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent>
          <Box
            sx={{
              position: 'relative',
              paddingBottom: '56.25%', // 16:9 aspect ratio
              height: 0,
              overflow: 'hidden',
              width: '100%',
              maxWidth: '800px',
              margin: '0 auto',
            }}
          >
            <Typography variant="h1" component="h1" textAlign="center" sx={{
                my: 15,
                fontWeight: "bold",
                }}
                gutterBottom
                >
                Demo Video Coming Soon
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};
