import React from 'react';
import { Container, Typography, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';

const jobPostings = [
  // Example job postings
  {title: 'Designer', department: 'Design', location: 'Remote', description: 'Design and create visual assets for web and print.'},
//   { title: 'Software Engineer', department: 'Engineering', location: 'New York, NY', description: 'Develop and maintain software applications.' },
//   { title: 'Product Manager', department: 'Product', location: 'San Francisco, CA', description: 'Oversee product development and strategy.' },
  // Add more job postings as needed or leave empty for "no job postings" message
];

const Careers = () => {
  return (
    <div className="flex-container">
        <Header />
        <div className="content">
            <Container>
            {/* Header section */}
            <Box sx={{ textAlign: 'center', my: 4 }}>
                <Typography variant="h2" gutterBottom>
                Join Our Team
                </Typography>
                <Typography variant="body1" gutterBottom>
                We're always looking for talented individuals to join our team. Check out our current job openings below.
                </Typography>
            </Box>
            
            {/* Job listings table */}
            {/* Displays job postings in a table if there are any, if there aren't any job postings then displays message saying check back later */}
            {jobPostings.length > 0 ? (
                <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell><b>Job Title</b></TableCell>
                        <TableCell><b>Department</b></TableCell>
                        <TableCell><b>Location</b></TableCell>
                        <TableCell><b>Description</b></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {/* Map through jobPostings array to create table rows */}
                    {jobPostings.map((job, index) => (
                        <TableRow key={index}>
                        <TableCell>{job.title}</TableCell>
                        <TableCell>{job.department}</TableCell>
                        <TableCell>{job.location}</TableCell>
                        <TableCell>{job.description}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            ) : (
                <Box sx={{ textAlign: 'center', my: 4 }}>
                <Typography variant="h6" color="textSecondary">
                    Sorry, come back later to see if we are hiring.
                </Typography>
                </Box>
            )}
            
            {/* Call to action button */}
            <Box sx={{ textAlign: 'center', my: 4 }}>
                <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/contact"
                >
                Contact Us to Apply
                </Button>
            </Box>
            </Container>
        </div>
        <Footer />
    </div>
  );
};

export default Careers;
