import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';

export function CallToActionSection() {
  return (
    <Container sx={{ py: 8, textAlign: 'center' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Still don’t believe us? Go see for yourself!
      </Typography>
      <Button variant="contained" color="primary" onClick={() => {
        window.location.href = 'https://www.customizedcollegeprep.com';
      }}>
        Preview Product
      </Button>
    </Container>
  );
};
