import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { LinkButton } from './LinkButton';

export function ContactUsSection() {
  return (
    <Container sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h4" component="h2" gutterBottom>
        Questions? Send us a message!
        </Typography>
        <LinkButton link="/contact" text="Contact Us" />
    </Container>
  );
};
