import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@mui/material';
import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';

const CollegeCounseling = () => {
    const navigate = useNavigate();

    const goToDoranAndScalora = () => {
        // Redirect to the 3rd party site
        window.location.href = 'https://www.doranandscalora.com/new-page-1';
    };

    const publicUrl = process.env.PUBLIC_URL;

    const houstonAreaTitle = "Houston-Area In-Person College Counseling";
    const houstonAreaDescription = "We provide in-person college counseling services in the Houston area through our IAMAI partner network.";

    const aiCounselorTitle = "Personalized AI College Counselor";
    const aiCounselorDescription = (
        <>
            Our AI College Counselor helps high school students discover universities and colleges that match their unique profiles. The counselor takes into account various factors such as academics, greek life, sports culture, preferred geography, and finances to provide tailored recommendations. With our AI-powered tool, students can:
            <ul>
                <li>Generate detailed college reports highlighting important aspects of the universities they are interested in.</li>
                <li>Discover colleges based on specific criteria through advanced filtering options.</li>
                <li>Receive a 'fit' estimation for each school based on factors relevant to the student.</li>
                <li>Stay updated with an ongoing college blog that offers insights and tips on the college application process.</li>
            </ul>
            <b>Leverage the power of AI to make informed decisions about <u>YOUR</u> higher education journey.</b>
        </>
    );

    return (
        <div className="flex-container">
            <Header />
            <div className="content">
                {/* Hero Section */}
                <Box
                    sx={{
                        position: 'relative',
                        color: 'white',
                        textAlign: 'center',
                        py: 10,
                        backgroundImage: `url(${publicUrl}/images/college-counseling-hero.jpg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        filter: 'brightness(100%)',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        }}
                    />
                    <Box sx={{ position: 'relative' }}>
                        <Typography variant="h2" gutterBottom>College Counseling Services</Typography>
                        <Typography variant="h6">
                            Guiding you through your college journey with personalized support.
                        </Typography>
                    </Box>
                </Box>

                {/* Houston-Area In-Person College Counseling */}
                <Container sx={{ py: 8 }}>
                    <Box sx={{ textAlign: 'center', mb: 4 }}>
                        <Typography variant="h4" gutterBottom>{houstonAreaTitle}</Typography>
                        <Typography variant="body1">
                            {houstonAreaDescription}
                        </Typography>
                        <Button variant="contained" color="primary" onClick={goToDoranAndScalora} sx={{ mt: 4 }}>
                            Get Started
                        </Button>
                    </Box>

                    {/* Personalized AI College Counselor */}
                    <Box sx={{ textAlign: 'center', mt: 8 }}>
                        <Typography variant="h4" gutterBottom>{aiCounselorTitle}</Typography>
                        <Typography variant="body1" mb={4}>
                            {aiCounselorDescription}
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => navigate('/signup')}>
                            Try Our AI College Counselor
                        </Button>
                    </Box>
                </Container>
            </div>
            <Footer />
        </div>
    );
}

export default CollegeCounseling;
