import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Button, MenuItem } from '@mui/material';
import axios from 'axios';

export function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactReason: '',
    subject: '',
    message: ''
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(formData);
      const response = await axios.post('https://4r2btbgx96.execute-api.us-east-1.amazonaws.com/dev/contact-form', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Failed to send your message.');
    }
  };

  return (
    <Container sx={{ py: 8, textAlign: 'center', maxWidth: '600px' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Contact Us
      </Typography>
      <Box
        component="form"
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          label="Name"
          variant="outlined"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'black',
            },
          }}
        />
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'black',
            },
          }}
        />
        <TextField
          select
          label="Contact Reason"
          variant="outlined"
          name="contactReason"
          value={formData.contactReason}
          onChange={handleChange}
          required
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'black',
            },
          }}
        >
          <MenuItem value="Investor Inquiry">Investor Inquiry</MenuItem>
          <MenuItem value="Careers (work for us!)">Careers (work for us!)</MenuItem>
          <MenuItem value="Customer Inquiry">Customer Inquiry</MenuItem>
          <MenuItem value="Partnerships">Partnerships</MenuItem>
        </TextField>
        <TextField
          label="Subject"
          variant="outlined"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'black',
            },
          }}
        />
        <TextField
          label="Message"
          variant="outlined"
          name="message"
          value={formData.message}
          onChange={handleChange}
          multiline
          rows={4}
          required
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'black',
            },
          }}
        />
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </Box>
      {message && <Typography variant="body1" color="textSecondary" mt={2}>{message}</Typography>}
    </Container>
  );
}
