import '../App.css';
import React from 'react';
import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';
import { ContactForm } from '../Components/ContactForm';


export default function Contact() {
  return (
    <div className="flex-container">
      <Header />
      <div className="content">
        <ContactForm />
      </div>  
      <Footer />
    </div>
  );
}
