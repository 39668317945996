import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

export function Header() {
  const navigate = useNavigate();
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  // menu refers to the type of menu that is currently open. Right now there is only one type of menu, products, but in the future there may be more.
  const [menuType, setMenuType] = useState('');

  const setSelectedMenu = (event, type="products") => {
    setDropdownAnchor(event.currentTarget);
    setMenuType(type);
  };

  const handleClose = () => {
    setDropdownAnchor(null);
    setMenuType('');
  };

  const navigateToRoute = (route) => {
    navigate(route);
    handleClose();
  };

  const publicUrl = process.env.PUBLIC_URL;
  const logo = publicUrl + '/images/logo.jpg';

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '16px' }} />
        <Typography variant="h6" style={{ flexGrow: 1, fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigateToRoute('/')}>
          IAMAI
        </Typography>
        
        <Button color="inherit" onClick={() => navigateToRoute('/about')}>
          About
        </Button>
        <Button color="inherit" onClick={() => navigateToRoute('/partnerships')}>
          Partnerships
        </Button>
        <Button color="inherit" onClick={() => navigateToRoute('/careers')}>
          Careers
        </Button>
        <Button color="inherit" onClick={() => navigateToRoute('/tutors')}>
          Tutors
        </Button>
        <Button color="inherit" onClick={(e) => setSelectedMenu(e)}>
          Products and Services
        </Button>
        <Menu
          anchorEl={dropdownAnchor}
          open={Boolean(dropdownAnchor)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigateToRoute('/products/academic-courses')}>Academic Courses</MenuItem>
          <MenuItem onClick={() => navigateToRoute('/products/college-counseling')}>College Counseling</MenuItem>
          <MenuItem onClick={() => navigateToRoute('/products/standardized-testing')}>Standardized Testing</MenuItem>
        </Menu>
        <Button color="inherit" onClick={() => navigateToRoute('/pricing')}>
          Pricing
        </Button>
        <Button color="inherit" onClick={() => navigateToRoute('/contact')}>
          Contact
        </Button>
        <Button color="inherit" onClick={() => {
            window.location.href = 'https://www.customizedcollegeprep.com';
        }}>
          Log In
        </Button>
      </Toolbar> 
    </AppBar>
  );
}
