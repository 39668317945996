import '../App.css';
import React from 'react';
import { Header } from '../Components/Header';
import { HeroSection } from '../Components/HeroSection';
import { VideoSection } from '../Components/VideoSection';
import { VideoSectionComingSoon } from '../Components/VideoSectionComingSoon';

import { TestimonialsSection } from '../Components/TestimonialsSection';
import { CallToActionSection } from '../Components/CallToActionSection';
import { NewsletterSignupSection } from '../Components/NewsletterSignupSection';
import { ContactUsSection } from '../Components/ContactUsSection';
import { Footer } from '../Components/Footer';
import { Divider } from '@mui/material';

function Home() {
  return (
    <div className="Home">
      <Header />
      <HeroSection />\
      {/* <VideoSection /> */}
      <VideoSectionComingSoon />
      {/* <TestimonialsSection /> */}
      <Divider />
      <CallToActionSection />
      <Divider />
      <NewsletterSignupSection />
      <Divider />
      <ContactUsSection />
      <Footer />
    </div>
  );
}

export default Home;
