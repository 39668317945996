import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Button } from '@mui/material';
import axios from 'axios';

export function NewsletterSignupSection() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://4r2btbgx96.execute-api.us-east-1.amazonaws.com/dev/newsletter-sign-up', { email });
      setMessage('Sucessfully subscribed! Check your email to confirm your subscription.');
    } catch (error) {
      setMessage('Failed to subscribe.');
    }
  };

  return (
    <Container sx={{ py: 8, textAlign: 'center' }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Want to stay up to date with our community? Sign up for our newsletter!
      </Typography>
      <Box
        component="form"
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          sx={{ mr: 2, width: '300px' }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button variant="contained" color="primary" type="submit">
          Subscribe
        </Button>
      </Box>
      {message && (
        <Typography variant="body1" component="p" sx={{ mt: 2 }}>
          {message}
        </Typography>
      )}
    </Container>
  );
};

export default NewsletterSignupSection;
