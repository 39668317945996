import React from 'react';
import { Container, Grid, Typography, Link, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function Footer() {
  const navigate = useNavigate();

  const clickHandler = (route) => {
    navigate(route);
  };
  return (
    <Box sx={{ py: 4, textAlign: 'center', backgroundColor: 'darkgray', color: 'white' }}>
            <Typography variant="body2">© 2024 IAMAI. All rights reserved.</Typography>
            <Box sx={{ mt: 2 }}>
            <a href="#" style={{ color: 'white', margin: '0 8px' }} onClick={(e) => { e.preventDefault(); clickHandler('/privacy-policy'); }}>Privacy Policy</a>
            <a href="#" style={{ color: 'white', margin: '0 8px' }} onClick={(e) => { e.preventDefault(); clickHandler('/tos'); }}>Terms of Service</a>
            <a href="#" style={{ color: 'white', margin: '0 8px' }} onClick={(e) => { e.preventDefault(); clickHandler('/contact'); }}>Contact</a>
            </Box>
        </Box>
  );
};
