import React from 'react';
import { Container, Box, Typography, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { Header } from '../Components/Header';
import { Footer } from '../Components/Footer';

const Tutor = () => {
    const tutors = [
        {
            name: 'Jane Doe',
            bio: 'Expert in Mathematics with 10 years of teaching experience.',
            image: '/path/to/jane.jpg'
        },
        {
            name: 'John Smith',
            bio: 'Passionate English teacher with a focus on literature and writing skills.',
            image: '/path/to/john.jpg'
        },
    ];

    return (
        <div className="flex-container">
            <Header />
            <div className="content">
                {/* Hero Section */}
                <Box
                    sx={{
                        position: 'relative',
                        color: 'black',
                        textAlign: 'center',
                        py: 10,
                        backgroundImage: `url('/path/to/hero-image.jpg')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        filter: 'brightness(50%)',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        }}
                    />
                    <Box sx={{ position: 'relative' }}>
                        <Typography variant="h2" gutterBottom>Tutors and Services</Typography>
                        <Typography variant="h6">
                            Find the right tutor and explore our range of tutoring services.
                        </Typography>
                    </Box>
                </Box>

                {/* Tutor Grid */}
                <Container sx={{ py: 8 }}>
                    <Grid container spacing={4}>
                        {tutors.map((tutor, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={tutor.image}
                                        alt={tutor.name}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">{tutor.name}</Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {tutor.bio}
                                        </Typography>
                                    </CardContent>
                                    <Button variant="contained" color="primary" sx={{ m: 2 }}>
                                        Learn More
                                    </Button>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
            <Footer />
        </div>
    );
}

export default Tutor;
