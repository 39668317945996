import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';

export function HeroSection() {
  const image_url = process.env.PUBLIC_URL + '/images/home-hero-image.webp';
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundImage: 'url(' + image_url + ')', 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        textAlign: 'center',
        py: 8, // padding top and bottom
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
        }}
      />
      <Container
        sx={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom>
          Welcome to IAMAI
        </Typography>
        {/* <Typography variant="h5" component="p" gutterBottom>
          Slogan about site
        </Typography> */}
        <Button sx={{my: 2}} variant="contained" color="secondary"
        onClick={() => {
            window.location.href = 'https://www.customizedcollegeprep.com';
        }}>
          Log In
        </Button>
      </Container>
    </Box>
  );
};

export default HeroSection;
